// firebase imports
import { db } from '../firebase/config'
import { collection, query, where, getDocs } from 'firebase/firestore'

// import store from '@/store'

const getDocumentByParam = async (c, param, value) => {

  const q = query(collection(db, c), where(param, "==", value));

  let documents = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.id, " => ", doc.data());
    documents = [...documents, {
      ...doc.data(),
      id: doc.id
    }];

  });

  return { documents }

};

export default getDocumentByParam