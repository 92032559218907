<template>

    <div v-if="document" class="js flexbox flexboxlegacy canvas canvastext webgl no-touch geolocation postmessage websqldatabase indexeddb hashchange history draganddrop websockets rgba hsla multiplebgs backgroundsize borderimage borderradius boxshadow textshadow opacity cssanimations csscolumns cssgradients cssreflections csstransforms csstransforms3d csstransitions fontface generatedcontent video audio localstorage sessionstorage webworkers no-applicationcache svg inlinesvg smil svgclippaths js-focus-visible" data-js-focus-visible="">

        <div>   
            <div class="container body-content">        


            <div class="container" style="display: flex;flex-direction: column;">


                <div class="row" style="flex-wrap: wrap-reverse;" id="TEST">
                    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 text-center">
                        <img v-if="document.laboratoryId=='tarqeem'" src="@/assets/img/tarqeem.png" class="logo">
                        <img v-else-if="document.laboratoryId=='zahran'" src="@/assets/img/tarqeem.png" class="logo">
                        <img v-else-if="document.laboratoryId=='sakr'" src="@/assets/img/sakr.png" class="logo">
                    </div>

                    <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 text-left">                        
                        <div v-if="document.laboratoryId=='tarqeem'" class="test-txt" style="margin-top: 0;padding-top: 0;text-align:center;margin-top:0;">Zahran Medical Laboratories - Mansoura - Egypt</div>
                        <div v-if="document.laboratoryId=='zahran'" class="test-txt" style="margin-top: 0;padding-top: 0;text-align:center;margin-top:0;">Zahran Medical Laboratories - Mansoura - Egypt</div>
                        <div v-if="document.laboratoryId=='sakr'" class="test-txt" style="margin-top: 0;padding-top: 0;text-align:center;margin-top:0;">Sakr Medical Laboratories - Mansoura - Egypt</div>
                    </div>
                    
                </div>
                <div class="row" style="flex-wrap: wrap-reverse;margin-top:3.5rem;" id="TEST">

                    <div class="col-md-7 text-left order-1 patientName">
                        <label class="label">Patient Name :</label><br>
                        <label class="text-label" style="font-weight:bold!important;color:#404776!important">{{ document.name }}</label>
                        <!-- HASSAN HANI HASSAN MOHAMED ELSAADANI -->
                    </div>

                </div>
                <div class="row" id="TEST" style="padding-top:30px;margin-top:1rem!important;">

                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 text-left ">
                        <div class="test-txt2" style="color:#585858!important;margin-top:0!important;">PCR testing for SARS-CoV2 Test Result</div>
                    </div>
                    
                    <div class="ol-md-6 col-lg-6 col-sm-12 col-xs-12 col-md-5 text-right order-2" style="float:right;">
                        <div v-if="document.testResult=='Positive'" class="result-box result-positive">{{ document.testResult }}</div>
                        <div v-else-if="document.testResult=='Negative'" class="result-box result-negative">{{ document.testResult }}</div>
                        <div v-else class="result-box result-neutral">{{ document.testResult }}</div>
                    </div>

                    
                </div>


                <form>
                    <div class="row" style="margin-top:2%">
                        <div class="bg-div col-md-3 col-lg-3 col-sm-6 col-xs-6 specimen" style="background: #0052b936;">
                            <label class=" col-form-label label" for="Gender">Specimen :</label><br>
                            <label class=" col-form-label text-label" id="Gender">Naso-pharyngeal swab</label>
                        </div>
                    </div>
                    <!-- <div class="row" style="margin-top:2%">

                        <div class="bg-div col-md-3 col-lg-3 col-sm-6 col-xs-6">
                            <label class=" col-form-label label" for="Gender">Gender :</label><br>
                            <label class=" col-form-label text-label" id="Gender">{{ document.gender }}</label>
                        </div>

                        <div class=" bg-div col-md-3 col-lg-3 col-sm-6 col-xs-6">

                            <label class=" col-form-label label" for="DOB">Date Of Birth :</label><br>
                            <label class=" col-form-label text-label" id="DOB">{{ document.dob }}</label>

                        </div>

                        <div class="bg-div  col-md-3 col-lg-3 col-sm-6 col-xs-6">
                            <label class=" col-form-label label" for="Nationality">Nationality :</label><br>
                            <label class=" col-form-label text-label" id="Nationality">Egypt</label>
                        </div>
                        <div class="bg-div  col-md-3 col-lg-3 col-sm-6 col-xs-6">
                            <label class=" col-form-label label" for="Passport">Passport No :</label><br>
                            <label class=" col-form-label text-label" id="Passport">{{ document.passportId }}</label>
                        </div>
                    </div> -->

                    <!-- <div class="row">

                        <div class="bg-div col-md-3 col-lg-3 col-sm-6 col-xs-6">
                            <label class=" col-form-label label" for="UaeID">ID No. :</label><br>
                            <label class=" col-form-label text-label" id="UaeID">{{ document.nationalId }}</label>
                        </div>

                        <div class="bg-div col-md-3 col-lg-3 col-sm-6 col-xs-6">
                            <label class="col-form-label label">Patient Number : </label><br>
                            <label class="col-form-label text-label">{{ document.patientId }}</label>
                        </div>
                    </div> -->

                    <div class="row">
                        <div class="col-md-6" style="height:30px;"></div>
                    </div>


                    <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style=" padding:10px;">
                            <label class=" col-form-label label" for="RequestDate">Gender :</label><br>
                            <label class=" col-form-label text-label" id="RequestDate">{{ document.gender }}</label>
                            <div class="div-line"></div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style=" padding:10px;">
                            <label class=" col-form-label label" for="RequestDate">Date Of Birth :</label><br>
                            <label class=" col-form-label text-label" id="RequestDate">{{  document.dob }}</label>
                            <div class="div-line"></div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style=" padding:10px;">
                            <label class=" col-form-label label" for="RequestDate">Passport No :</label><br>
                            <label class=" col-form-label text-label" id="RequestDate">{{ document.passportId }}</label>
                            <div class="div-line"></div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style=" padding:10px;">
                            <label class=" col-form-label label" for="RequestDate">ID No. :</label><br>
                            <label class=" col-form-label text-label" id="RequestDate">{{ document.nationalId }}</label>
                            <div class="div-line"></div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style=" padding:10px;">
                            <label class=" col-form-label label" for="RequestDate">Patient Number :</label><br>
                            <label class=" col-form-label text-label" id="RequestDate">{{ document.patientId }}</label>
                            <div class="div-line"></div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style=" padding:10px;">
                            <label class=" col-form-label label" for="AccNumber">Accession Number :</label><br>
                            <label class=" col-form-label text-label" id="AccNumber">{{ document.accessionId }}</label>
                            <div class="div-line"></div>
                        </div>

                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style=" padding:10px;">
                            <label class=" col-form-label label" for="RequestDate">Request Date :</label><br>
                            <label class=" col-form-label text-label" id="RequestDate">{{ document.requestTime }}</label>
                            <div class="div-line"></div>
                        </div>

                        <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style=" padding:10px;">
                            <label class=" col-form-label label" for="CollectionDate">Collection Date :</label><br>
                            <label class=" col-form-label text-label" id="CollectionDate">{{ document.collectionTime }}</label>
                            <div class="div-line"></div>
                        </div> -->
                    <!-- </div>


                    <div class="row"> -->
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style=" padding:10px;">
                            <label class=" col-form-label label" for="ReportingDate">Reporting Date :</label><br>
                            <label class=" col-form-label text-label" id="ReportingDate">{{ document.reportingTime }}</label>

                            <div class="div-line"></div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" style="text-align: center;margin-top: 1.5rem;">
                            <label v-if="document.laboratoryId=='tarqeem'" class="Performed-txt2" id="TestPerformedBy" style="color:#3b47d7">ترخيص وزارة الصحة رقم: 9077</label>
                            <label v-else-if="document.laboratoryId=='zahran'" class="Performed-txt2" id="TestPerformedBy" style="color:#3b47d7">ترخيص وزارة الصحة رقم: 9077</label>
                            <label v-else-if="document.laboratoryId=='sakr'" class="Performed-txt2" id="TestPerformedBy" style="color:#3b47d7">ترخيص وزارة الصحة رقم: 11386</label>
                        </div>
                    </div>


                </form>

                <div class="stamp" style="width: auto;right:0;float:right;margin:auto;">
                    <img v-if="document.laboratoryId=='tarqeem'" style="width: auto;" src="@/assets/img/zahran-stamp.png" class="logo">
                    <img v-else-if="document.laboratoryId=='zahran'" style="width: auto;" src="@/assets/img/zahran-stamp.png" class="logo">
                    <img v-else-if="document.laboratoryId=='sakr'" style="width: auto;" src="@/assets/img/sakr-stamp.png" class="logo">
                </div>


            </div>

            <footer class="footer">
                <div class="container">
                    <span class="footer-txt" style="padding-bottom: 1rem;">
                    <!-- Copyright © 2021-2022 <a href="" target="_blank">National Technology Company, </a> All rights reserved.<br> -->
                    This is an electronically generated report and does not require a signature.
                    </span>
                </div>
            </footer>

                
            </div>
        </div>

    </div>

    <div v-else class="h-100 row align-items-center" style="height: 100%;
                                                        width: 100%;
                                                        justify-content: center;
                                                        align-items: center;
                                                        justify-items: center;
                                                        display: flex;">
        <div class="loader"></div>
    </div>
  

</template>

<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import getDocumentByParam from '@/composables/getDocumentByParam';

export default {
    setup() {

        // https://green-f4642.firebaseapp.com/#/tarqeem-e9daccf4-c4e5-4b28-ac76-8da285e18444
        // https://results.yaseerlis.net/#/tarqeem-e9daccf4-c4e5-4b28-ac76-8da285e18444
        
        const document = ref(null);
        const route = useRoute()
        // alert(route.params.uid);

        const getData = async () => {
            const { documents } = await getDocumentByParam(
                'results',
                '_id',
                route.params.uid
            )

            if ( documents.length ) document.value = [...documents].pop();

        }

        getData();

        return { getData, document };
        
    },
}
</script>

<style scoped>
    .result-negative{
        background-color: #137e13;
        color: #fff;
        border: 5px solid #51cb41;
        border: 5px solid #359f28;        
        border-radius:10px!important;
        font-size: 2.25rem;
     }
    .result-positive{
        color:#fff;
        background: #930808!important;
        border: 5px solid #e74848!important;
        border: 5px solid #c33737!important;
        border-radius:10px!important;
        font-size: 2.25rem;
    }
    .result-neutral{
        background-color: #606c2d;
        color: #fff;
        border: 5px solid #818d4e;
        border-radius: 10px!important;
        font-size: 2.25rem;
    }

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 0.75s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 750px) {
  .specimen {
    width: 100%!important;
  }
  .test-txt2{
    text-align: center;
    margin-block: 1rem;;
  }
  .order-2 {
      padding:0!important;
  }
  .result-box {
      width:100%!important;
  }
  .stamp {
      margin: 0.75rem 0;
      margin-right: 1rem;
  }
  .patientName {
      text-align: center!important;
  }
}
</style>


